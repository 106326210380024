/**
 * This is the production configuration.
 */

export const GLOBALS = {
  name: '',
  production: false,
  apiConfiguration: {
    baseUrl: 'https://api.band-manager.app',
  },
  translation_path: './assets/i18n/',
};
